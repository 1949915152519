export enum SkuStatus {
    PURCHASEABLE = "purchasable",
    OWNED = "owned",
    CLAIMING = "claiming"
}

export enum SkuType {
    SILVER_PACKAGE = "silver-package",
    AVATAR = "avatar",
    OFFER = "offer"
}

export enum OfferPeriod {
    DAILY = "daily",
    MONTHLY = "monthly",
    WEEKLY = "weekly"
}

export enum ContentResources {
    SILVERS = "res-silver",
    FOOD = "res-food",
    WOOD = "res-wood",
    ORE = "res-ore",
    SPEEDUP_GENERAL = "speedup-general",
    SPEEDUP_HEALING = "speedup-healing",
    SPEEDUP_TRAINING = "speedup-training",
    SPEEDUP_BUILDING = "speedup-building",
    SPEEDUP_RESEARCH = "speedup-research"
}

export enum ContentResourcesTypes {
    SILVER_PACKAGE = "silver-package",
    RESOURCE_PACKAGE = "resource-package",
    SPEEDUP_PACKAGE = "speed-up-package"
}

export interface Sku {
    content: string,
    name: string,
    price: number, 
    status: SkuStatus,
    type: SkuType,
    _id: string,
    is_visible: boolean,
    BonusPercent: number,
    TimeEnd: number,
    TimeStart: number,
    OfferPeriod: OfferPeriod,
    imageUrl?: string,
    //added fields
    parsedContent?: Record<string,any>,
    avatarImg?: any,
    coverImg?: any,
    tagImg?: any,
    detailImg?: any,
    headingColor?: string,
    descriptionColor?: string,
    description?: string
}