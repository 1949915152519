import React from "react";
import Modal from "./Modal";
//@ts-ignore
import { useRealmApp } from "../../utils/RealmApp";

type Props = {
  email: string;
  action: "reset" | "confirm";
};

const ConfirmEmailDialog = ({ action, email }: Props) => {
  const realmApp: Realm.App = useRealmApp();
  const intervalRef = React.useRef<NodeJS.Timer>();
  const [errors, setErrors] = React.useState<Record<string, string>>();

  const [code, setCode] = React.useState("");
  const [timeLeft, setTimeLeft] = React.useState(1800); // 30 minutes
  const [disableResend, setDisableResend] = React.useState(false);

  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  }, [setTimeLeft]);

  React.useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current as any);
    }
  }, [timeLeft]);

  const resendCode = async () => {
    try {
      //disable resend button
      setDisableResend(true);
      await realmApp.emailPasswordAuth.resendConfirmationEmail({ email });
      //if new code generated, reset time left
      setTimeLeft(1800);
      //enable resend Button
      setDisableResend(false);
    } catch (e: any) {
      setErrors({ _: e.error });
    }
  };

  const minutes = `${Math.floor(timeLeft / 60)}`;
  const seconds = `${timeLeft % 60}`;
  const timeString = `${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;

  return (
    <>
      {action !== "reset" ? ( //can't request a new code if resetting password
        <div className="flex flex-col w-96 mx-auto text-white gap-5">
          <div className="font-cinzel text-[28px] font-bold mb-8 text-center mt-6 text-antique">
            Verify Email
          </div>
          <div>
          <div className="text-center text-[18px] font-normal">
            An email with a verification link was sent to:
          </div>
          <div className="text-center text-[18px] font-bold mb-8">
            {email}
          </div>
          </div>
          <div className="text-center text-[18px] font-normal mb-8">
            Please check your inbox or spam folder, and click on the link in the
            email to activate your account!
          </div>

          <div className="font-cinzel font-bold text-[18px] text-center mb-8 text-antique">
            {timeLeft <= 0 ? (
              <span>Code expired!</span>
            ) : (
              <span>
                Code expires in{" "}
                <span className="text-khaki-light">{timeString}</span>
              </span>
            )}
          </div>

          <div className="text-center text-[18px] mb-8">
            Didn't receive a verification email?{" "}
            <button
              className={"underline text-" + (disableResend ? 'gray-300' : 'blue')}
              disabled={disableResend}
              onClick={() => resendCode()}
            >
              Send a new code.
            </button>
            {errors?._ && (
              <div className="font-garamond text-red mb-6">{errors._}</div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-96 mx-auto text-white gap-5">
          <div className="font-cinzel text-[28px] font-bold mb-8 text-center mt-6 text-antique">
            Verify Email
          </div>
          <div className="text-center text-[18px] font-normal">
            An email with a verification link was sent to:
          </div>
          <div className="text-center text-[18px] font-bold mb-8">
            {email}
          </div>
          <div className="text-center text-[18px] font-normal mb-8">
            Please check your inbox or spam folder, and click on the link in the
            email to reset your password!
          </div>

          <div className="font-cinzel font-bold text-[18px] text-center mb-8 text-antique">
            {timeLeft <= 0 ? (
              <span>Code expired!</span>
            ) : (
              <span>
                Code expires in{" "}
                <span className="text-khaki-light">{timeString}</span>
              </span>
            )}
          </div>
        </div>
      )}
      </>
  );
};

export default ConfirmEmailDialog;
