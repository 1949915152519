import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import * as React from "react";
import { environment } from "../utils/environment";
import { WagmiConfig } from "wagmi";


const projectId = environment.wallet_connect_project_id;

const chains = environment.chains.map(chain => ({
  ...chain,
  rpcUrls: { //wagmi version require public rpc url, set default rpc url as public
    ...chain.rpcUrls,
    public: chain.rpcUrls.default,
  },
}));

const metadata = {
  name: "Medieval Empires",
  description: "Medieval Empires",
  url: process.env.GATSBY_DOMAIN_NAME,
  icons: [
    "https://stake.apps.medievalempires.com/static/coin-c2cfd06be2bb48c2d63ff3714da694ac.png",
  ],
};

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
createWeb3Modal({ wagmiConfig, projectId, chains });

export const WalletConnect = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
  );
};

export const Web3Button = () => {
   //@ts-ignore
  return (<w3m-button />)
}
