import React, { useState } from "react";
import Modal from "./Modal";
import {
  armor,
  closeIcon,
  crossIconWhite,
  kayiArcher,
  mobileModalHeader,
  registerModalBg,
} from "../../images/general";
import CustomButtton from "../Button/CustomButtton";
import { useAuth } from "../../context/AuthContext";
import * as Realm from "realm-web";
//@ts-ignore
import { useRealmApp } from "../../utils/RealmApp";
import ConfirmEmailDialog from "./ConfirmOrResetEmail";
import { Link } from "gatsby";
import { constants } from "../../utils/constants";

const RegisterModal = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) => {
  const realmApp: Realm.App = useRealmApp();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmation, setConfirmation] = React.useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [acceptTerms, setAcceptTerms] = React.useState<
    boolean | "indeterminate"
  >(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>>();
  const isDisabled = isLoading || !email || !password || !confirmation;

  const onRegisterClose = () => {
    onClose();
    setTimeout(() => {
      setEmail("");
      setPassword("");
      setConfirmation("");
      setIsSuccess(false);
    },2000);
  }

  const {
    actions: { setIsLoginModalOpen },
  } = useAuth();

  const onLogin = () => {
    setIsLoginModalOpen(true);
    onClose();
  };

  const onSubmit = async () => {
    setErrors(undefined);
    setIsLoading(true);

    try {
      if (confirmation === password) {
        await realmApp.emailPasswordAuth.registerUser({ email, password });
        // navigate("/confirm?email=" + encodeURIComponent(email));
        setIsSuccess(true);
      } else {
        setErrors({ _: "passwords do not match" });
      }

      //onClose();
    } catch (e: any) {
      if (e?.errorCode && e?.errorCode === "AccountNameInUse") {
        //resend verification mail
        await realmApp.emailPasswordAuth
          .resendConfirmationEmail({ email })
         .then((resp => setIsSuccess(true)))
          .catch((err) => {
            //if email already verified then show registeration error
            if (err?.errorCode && err.errorCode === "UserAlreadyConfirmed") {
              setErrors({ _: e.error });
            }
          });
      } else if (e.error) {
        setErrors({ _: e.error });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isLoading) return;
    if (e.code === "Enter") {
      onSubmit();
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      className="bg-[#23262B] sm:max-w[880px] sm:w-[880px] sm:scale-100 w-full scale-90 bg-no-repeat bg-cover bg-center py-2 h-fit m-auto flex sm:flex-row flex-col-reverse"
    >
      <div className="sm:w-[65%] w-full h-[35rem] overflow-y-auto sm:h-fit">
        {isSuccess ? (
          <ConfirmEmailDialog email={email} action="confirm" />
        ) : (
          <>
            <div className="sm:px-[3.7rem] px-4 sm:pt-11 pt-3">
              <h3 className="text-2xl sm:text-[40px] leading-10 text-white font-bold font-cinzel sm:mb-8 mb-3">
                Create Account
              </h3>
              <form>
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="block text-white text-sm leading-6 font-semibold mb-3"
                  >
                    Email address *
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value?.toLowerCase())}
                    onKeyDown={onKeyDown}
                    className=" border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="Password"
                    className="block text-white text-sm leading-6 font-semibold mb-3"
                  >
                    Password *
                  </label>
                  <input
                    placeholder="Choose a secure password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    onKeyDown={onKeyDown}
                    className="border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
                  />
                </div>
                <div className="mb-1">
                  <label
                    htmlFor="text"
                    className="block text-white text-sm leading-6 font-semibold mb-3"
                  >
                    Confirm Password *
                  </label>
                  <input
                    placeholder="Type the same password again"
                    type="password"
                    name="cofirmation"
                    value={confirmation}
                    onChange={(event) => setConfirmation(event.target.value)}
                    onKeyDown={onKeyDown}
                    className="border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
                  />
                </div>
                <p className="text-xs leading-6 text-[#B1BDC8] mb-5">
                  You can change this at a later point.
                </p>
                <div className="mb-3">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    className="mr-2 bg-transparent cursor-pointer"
                  />
                  <label
                    htmlFor="rememberMe"
                    className="text-xs leading-6 text-white "
                  >
                    I want exclusive items and email updates
                  </label>
                </div>
              </form>
            </div>
            <hr className="border-t-1 border-[#435565]" />
            <div className="sm:px-[3.7rem] px-4  py-4">
              <p className="text-white text-sm leading-6">
                By clicking Create Account, you are agreeing to our{" "}
                <a href={constants.app_routes.privacy_policy} target='_blank' className="text-[#7CC0FF] hover:underline">Privacy Policy</a> and{" "}
                <a href={constants.app_routes.terms_and_conditions} target='_blank' className="text-[#7CC0FF] hover:underline">Terms of Service</a>*
              </p>
            </div>
            <hr className="border-t-1 border-[#435565]" />
            {errors && errors?._ && (
              <div className="font-garamond text-red sm:px-[3.7rem] px-4">
                {errors?._}
              </div>
            )}
            <div className="sm:px-[3.7rem] px-4  py-4">
              <div className="mt-4">
                <CustomButtton
                  isLoading={isLoading}
                  disabled={isDisabled}
                  text="Create Account"
                  icon2={armor}
                  onClick={onSubmit}
                />
                <p className="text-white text-sm leading-6 mt-7 mb-5">
                  Already have an account?
                  <span
                    className="text-[#7CC0FF] font-semibold cursor-pointer"
                    onClick={onLogin}
                  >
                    {" "}
                    Login
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="sm:w-[35%] w-full bg-no-repeat bg-cover bg-center p-1 relative bg-[url('../images/general/registerModalMobileBg.png')] sm:bg-[url('../images/general/registerModalBg.png')]">
        <div
          className=" hidden sm:flex justify-end cursor-pointer"
          onClick={onRegisterClose}
        >
          <img src={closeIcon} alt="" />
        </div>
        <div className="hidden sm:block -mr-32">
          <img src={kayiArcher} alt="" />
        </div>
        <div
          className="sm:hidden w-full h-16 bg-no-repeat bg-cover bg-center px-8 flex items-center justify-between mb-36"
          style={{ backgroundImage: `url(${mobileModalHeader})` }}
        >
          <h1 className="text-white font-normal text-sm leading-6">
            Create Account
          </h1>
          <span className="text-black text-xl cursor-pointer" onClick={onRegisterClose}>
            <img src={crossIconWhite} alt="" />
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default RegisterModal;
