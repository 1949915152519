import { config, passport, blockchainData } from "@imtbl/sdk";
import { Web3Provider } from '@ethersproject/providers';
import { environment } from "../../utils/environment";
import { getWindow } from "../../utils";


const defaultRedirectionUri = `https://${process.env.GATSBY_DOMAIN_NAME}`;
const imxRedirectionUri = getWindow()?.location?.origin || defaultRedirectionUri;

export const passportInstance = new passport.Passport({
  baseConfig: new config.ImmutableConfiguration({
    environment: environment.imx_environment,
  }),
  clientId: environment.imx_client_id,
  redirectUri: imxRedirectionUri,
  logoutRedirectUri: imxRedirectionUri,
  audience: "platform_api",
  scope: "openid offline_access email transact",
});

const client = new blockchainData.BlockchainData({
  baseConfig: {
    environment: environment.imx_environment,
    publishableKey: environment.imx_publishable_key,
  },
});

export const getImxIdToken = async () => {
  try {
    const passportProvider = passportInstance?.connectEvm();
    if (passportProvider != null) {
      await passportInstance.login({useCachedSession: false});
      const idToken = await passportInstance.getIdToken();
      if (idToken) {
        return idToken;
      } else {
        throw new Error("Passport id token is not present");
      }
    } else {
      throw new Error("Passport Instance is not present");
    }
  } catch (error) {
    throw error;
  }
};

export const imxLogout = async () => {
  if(passportInstance) {
    await passportInstance.logout();
  }
}

export const executeImxLoginCallback = async () => {
    try {
        await passportInstance.loginCallback();
    }
    catch(e) {
        console.log("Error Imx Login Callback", e);
    }
}

export const getImxProvider = async () => {
  const passportProvider = passportInstance?.connectEvm();
  if(passportProvider != null) { 
    const provider = new Web3Provider(passportProvider);
    //fetch accounts
    await provider.send('eth_requestAccounts', []);
    return provider;
  }
  else {
    return null;
  }
}

export const getImxWalletAddress = async () => {
    try {
        const imxProvider = await getImxProvider();
        if(imxProvider != null) {
          return imxProvider.getSigner().getAddress();
        } else {
          throw new Error("IMX Wallet is not connected")
        }
      } catch (e) {
        throw e;
      }
}

export const getOwnedNfts = async (imxWalletAddress: string, nftContractAddress?: string) => {
  let ownedNfts: any[] = [];
  try {
    if(!imxWalletAddress) throw new Error("Imx Wallet Address Not Found");
    const ownedNftsResponse = await client.listNFTsByAccountAddress({
      accountAddress: imxWalletAddress,
      chainName: environment.imx_chain.id_str,
      contractAddress: nftContractAddress
    })
    ownedNfts = ownedNftsResponse.result;
  }
  catch(e) {
    console.log("Error while fetching NFTS from IMX", e);
  }
  return ownedNfts;
}