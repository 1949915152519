import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";

const Modal = ({
  isVisible,
  isModal = true,
  children,
  className,
}: {
  isVisible: boolean;
  children: React.ReactNode;
  isModal?: boolean;
  className?: string;
}) => {
  return (
    <Dialog.Root open={isVisible} modal={isModal}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-overlay fixed inset-0 pointer-events-auto z-[100]" />
        <Dialog.Content
          className={clsx(
            "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 py-4 focus:outline-none z-[100]",
            className
          )}
        >
          {/* Popup content */}
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
