import React from "react";
//@ts-ignore
import { AuthContextProvider } from "../context/AuthContext";
import { UiContextProvider } from "../context/UiContext";
import { SkuContextProvider } from "../context/SkuContext";

const ContextProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContextProvider>
      <UiContextProvider>
        <SkuContextProvider>
            {children}
        </SkuContextProvider>
      </UiContextProvider>
    </AuthContextProvider>
  );
};

export default ContextProviders;
