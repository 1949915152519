import React, { useEffect, useRef, useState } from "react";
import { useIsVisible } from "../../hooks/useIsVisible";

interface IAlertBanner {
  text: string;
  autoHide?: boolean;
}

const AlertBanner: React.FC<IAlertBanner> = ({ text, autoHide = false }) => {
  const bannerRef = useRef(null);
  const isInView = useIsVisible(bannerRef);
  //auto hide content if not in view
  const isHidden = autoHide && !isInView;

  return (
    <div ref={bannerRef}>
      {!isHidden && (
        <div
          className={`bg-red text-white text-center py-4 px-2 font-cinzel font-bold`}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default AlertBanner;
