import React, { useEffect } from "react";
//@ts-ignore
import { useRealmApp } from "../utils/RealmApp";
import { useSkuState } from "../context/SkuContext";
import { useUiState } from "../context/UiContext";
import { getSkuList } from "../services/mongoApiService";
import { Sku, SkuType } from "../types/Sku";
import { avatarsAssetMapping, silverPackagesAssetsMappings } from "../utils/marketplaceAssetsMapping";

export const useSku = () => {
    const realmApp: Realm.App = useRealmApp();
    const { currentUser } = realmApp;
    const {
        actions: { setSku },
    } = useSkuState();
    const {
        actions: { setIsLoading },
    } = useUiState();

    const getAndSetSkuList = async (silent = false) => {
        //call realm function if logged in else call API Endpont
        let skuList: Sku[] = [];
        if(!silent) setIsLoading(true);
        try {
          if (currentUser?.isLoggedIn) {
            skuList = (await currentUser?.functions?.getSkuList()) as Sku[];
            console.log("CALLED REALM FUNCTION FOR SKU ", skuList);
          } else {
            skuList = await getSkuList();
            console.log("CALLED API ENDPOINT FOR SKU ", skuList);
          }
          setSku(
            skuList.map((sku) => {
              //parse the content
              const parsedContent: any[] = sku?.content && JSON.parse(sku.content);
              if (parsedContent && parsedContent.length > 0) {
                sku.parsedContent = parsedContent.length === 1 ? parsedContent[0] : parsedContent;
              }
              if (sku.type === SkuType.SILVER_PACKAGE) {
                const silverAssetMapping = silverPackagesAssetsMappings.find(
                  (mp) => mp._id === sku._id
                );
                return { ...silverAssetMapping, ...sku };
              }
              if (sku.type === SkuType.AVATAR) {
                const avatarAssetMapping = avatarsAssetMapping.find(
                  (mp) => mp._id === sku._id
                );
                return { ...avatarAssetMapping, ...sku };
              }
              return sku;
            })
          );
        } catch (e) {
          console.log("Error while fetching SKU list");
        }
        if(!silent) setIsLoading(false);
        return skuList;
      };

      const getSkuById = async (skuId: string, silent = false) => {
        const skuList = await getAndSetSkuList(silent);
        const sku: Sku | undefined = skuList.find(sku => sku._id == skuId);
        return sku;
      };

      return {
        getAndSetSkuList,
        refreshSku: async () => await getAndSetSkuList(true),
        getSkuById
      }
}
