// Button.js
import React, { ButtonHTMLAttributes } from "react";

const types = {
  primary: {
    outerClass:
      "bg-redgradient p-0.5 border-[#FFADAC] border-2 rounded-xl hover:scale-110 transition duration-500",
    innerClass:
      "text-white bg-innerredbtn sm:justify-normal justify-center sm:w-max w-full text-sm px-6 flex items-center gap-3 rounded-lg border-[1px] border-[#FF6B69]",
  },
  secondary: {
    outerClass:
      "bg-bluegradient p-0.5 border-[#C7F8FF] border-2 rounded-xl hover:scale-110 transition duration-500",
    innerClass:
      "text-white bg-innerbluebtn sm:justify-normal justify-center sm:w-max w-full text-sm px-6 flex items-center gap-3 rounded-lg border-[1px] border-[#7EF0FF]",
  },
  cyan: {
    outerClass:
    "bg-innerCyanBtn p-0.5 border-[#4B7CA3] border-2 rounded-xl",
  innerClass:
    "text-white bg-innerCyanBtn sm:justify-normal justify-center sm:w-max w-full text-sm px-6 flex items-center gap-3 rounded-lg border-[1px] border-[#4B7CA3]"
  },
  blackGold: {
    outerClass:
      "bg-goldgradient p-0.5 border-[#7D3E03] border-1 rounded-xl hover:scale-110 transition duration-500",
    innerClass:
      "text-white bg-black sm:justify-normal justify-center sm:w-max w-full text-sm px-6 flex items-center gap-3 rounded-lg border-[1px] border-[#7D3E03]",
  },
  "disable-primary": {
    outerClass:
      "bg-gradientDisabled p-0.5 border-[#B7B1B1] border-2 rounded-xl",
    innerClass:
      "text-white bg-gradientDisabledInner sm:justify-normal justify-center sm:w-max w-full text-sm px-6 flex items-center gap-3 rounded-lg border-[1px] border-[#C9B9B9]",
  },
  "disable-secondary": {
      outerClass:
        "",
      innerClass:
        "text-[#595959] bg-transparent sm:justify-normal justify-center sm:w-max w-full text-sm px-6 flex items-center gap-3 rounded-lg border-[1px] border-[#595959]",
  }
};

const size = {
  small: "py-2",
  medium: "py-4",
  large: "py-6",
};

const CustomButtton = ({
  text,
  icon1,
  icon2,
  btnType = "primary",
  btnSize = "medium",
  isLoading = false,
  onClick = () => {},
  disableType = "primary",
  ...rest
}: {
  text: string | React.ReactNode;
  onClick?: any;
  icon1?: any;
  icon2?: any;
  isLoading?: boolean;
  btnType?: "primary" | "secondary" | "cyan" | "blackGold";
  btnSize?: "small" | "medium" | "large";
  disableType?: "primary" | "secondary"
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const isDisable = rest.disabled;
  //@ts-ignore
  const outerClass = types[isDisable ? `disable-${disableType}` : btnType].outerClass;
   //@ts-ignore
  const innerClass = types[isDisable ? `disable-${disableType}` : btnType].innerClass;

  return (
    <div className="sm:w-max w-fit mx-auto sm:mx-0 mb-3 xl:mb-0 shadow-customdivshadow ">
      <div className={outerClass}>
        <button
          className={
            innerClass +
            " " +
            size[btnSize]
          }
          onClick={onClick}
          {...rest}
        >
          {icon1 && (
            <span className="w-12 sm:w-fit">
              <img src={icon1} alt="" />
            </span>
          )}
          {text}
          {icon2 ? (
            <span className={isLoading ? "animate-spin" : ""}>
              <img src={icon2} alt="" style={isDisable ? { opacity: 0.3 } : {}} />
            </span>
          ) : isLoading ? (
            <div
              className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          ) : null}
        </button>
      </div>
    </div>
  );
};

export default CustomButtton;
