import React from "react";
import { useCookies } from "react-cookie";
import CustomButtton from "../../components/Button/CustomButtton";

const CookieConsent = () => {
  const [show, setShow] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "accepted",
    "marketing",
    "personalization",
    "analytics",
  ]);

  const acceptAll = () => {
    setCookie("accepted", true);
    setCookie("marketing", true);
    setCookie("analytics", true);
    setCookie("personalization", true);
  };

  const changePreference = ({ target }: { target : any}) => {
    if (target.checked) {
      setCookie(target.id, target.checked);
    } else {
      removeCookie(target.id);
    }
  };

  const savePreferences = () => {
    setCookie("accepted", true);
    setShow(false);
  };

  return (
    <>
      {!cookies.accepted ? (
        <div className="z-[998] text-white bg-[#222529] flex fixed shadow-[1px_0_0_8px_#2e3237,inset_0_0_0_1px_#787776] py-5 top-auto bottom-[0%] inset-x-[0%]">
          <div className="flex flex-col sm:flex-row justify-between items-center w-[90%] m-auto">
            <div>
              We use cookies to optimize site performance, enhance user
              experience, and measure site usage and our marketing efforts.
            </div>
            <div className="flex flex-row gap-4 items-center">
              <div
                onClick={() => setShow(true)}
                className="text-[#fff1e3] text-base font-normal leading-6 underline font-cinzel cursor-pointer"
              >
                Manage my preferences
              </div>
              <CustomButtton btnSize="small" onClick={acceptAll} text="Accept"/>
            </div>
          </div>
        </div>
      ) : (
        <div className="cookie-main" onClick={() => setShow(true)}>
          <div className="cookie-inner" title="Cookie Preferences">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 8L9 8.01"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M16 15L16 15.01"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M10 17L10 17.01"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M11 13L11 13.01"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M6 12L6 12.01"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 11.4402 20.9489 10.8924 20.8511 10.361C20.3413 10.7613 19.6985 11 19 11C18.4536 11 17.9413 10.8539 17.5 10.5987C17.0587 10.8539 16.5464 11 16 11C14.3431 11 13 9.65685 13 8C13 7.60975 13.0745 7.23691 13.2101 6.89492C11.9365 6.54821 11 5.38347 11 4C11 3.66387 11.0553 3.34065 11.1572 3.03894C6.58185 3.46383 3 7.31362 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      )}
      {show && (
        <div className="cookie-popup-overlay">
          <div className="cookie-popup-form">
            <h2 className="cookie-popup-heading-text">Cookies</h2>
            <p className="cookie-popup-text">
              When you visit our websites, they may store or retrieve
              information on your browser, mostly in the form of cookies. This
              information might be about you, your preferences or your device
              and is mostly used to make the site work as you expect it to. The
              information does not usually directly identify you, but it can
              give you a more personalized web experience. You can change your
              preferences below.
            </p>
            <h2 className="cookie-popup-heading-text">Manage Cookies</h2>
            <div className="cookie-margin-div">
              <div className="cookie-cookieFlex">
                <h3 className="cookie-flex-heading">Essential</h3>
                <h5 className="cookie-flex-active-heading">
                  <strong>Always Active</strong>
                </h5>
              </div>
              <p className="cookie-popup-text">
                These items are required to enable basic website functionality.
              </p>
            </div>
            <div className="cookie-cookieFlex">
              <h3 className="cookie-flex-heading">Marketing</h3>
              <label className="cookie-switch">
                <input
                  type="checkbox"
                  id="marketing"
                  defaultChecked={cookies.marketing}
                  onChange={changePreference}
                />
                <span className="cookie-slider"></span>
              </label>
            </div>
            <p className="cookie-popup-text cookie-mb-extra">
              These items are used to deliver advertising that is more relevant
              to you and your interests. They may also be used to limit the
              number of times you see an advertisement and measure the
              effectiveness of advertising campaigns. Advertising networks
              usually place them with the website operator’s permission.
            </p>
            <div className="cookie-cookieFlex">
              <h3 className="cookie-flex-heading">Personalization</h3>
              <label className="cookie-switch">
                <input
                  type="checkbox"
                  id="personalization"
                  defaultChecked={cookies.personalization}
                  onChange={changePreference}
                />
                <span className="cookie-slider"></span>
              </label>
            </div>
            <p className="cookie-popup-text cookie-mb-extra">
              These items allow the website to remember choices you make (such
              as your user name, language, or the region you are in) and provide
              enhanced, more personal features. For example, a website may
              provide you with local weather reports or traffic news by storing
              data about your current location.
            </p>
            <div className="cookie-cookieFlex">
              <h3 className="cookie-flex-heading">Analytics</h3>
              <label className="cookie-switch">
                <input
                  type="checkbox"
                  id="analytics"
                  defaultChecked={cookies.analytics}
                  onChange={changePreference}
                />
                <span className="cookie-slider"></span>
              </label>
            </div>
            <p className="cookie-popup-text cookie-mb-extra">
              These items help the website operator understand how its website
              performs, how visitors interact with the site, and whether there
              may be technical issues. This storage type usually doesn’t collect
              information that identifies a visitor.
            </p>
            <div className="cookie-text-right">
              <button className="cookie-close-button" onClick={savePreferences}>
                Confirm my preferences and close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;