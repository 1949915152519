import React from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { Helmet } from "react-helmet";

interface ISeoProps {
  title?: string;
  description?: string;
  pathName?: string;
  image?: string;
}

export const SEO = ({ title, description, pathName, image }: ISeoProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathName ? `/${pathName}` :  ``}`,
    twitterUsername,
  };

  return (
      <Helmet
        title={seo.title}
        meta={[
          {
            name: "description",
            content: seo.description,
          },
          {
            name: "image",
            content: seo.image,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:title",
            content: seo.title,
          },
          {
            name: "twitter:url",
            content: seo.url,
          },
          {
            name: "twitter:description",
            content: seo.description,
          },
          {
            name: "twitter:image",
            content: seo.image,
          },
          {
            name: "twitter:creator",
            content: seo.twitterUsername,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: seo.url,
          },
          {
            property: `og:title`,
            content: seo.title,
          },
          {
            property: `og:description`,
            content: seo.description,
          },
          {
            property: `og:image`,
            content: seo.image,
          },
          //   {
          //     property: `og:updated_time`,
          //     content: lastUpdated,
          //   },
          //   {
          //     property: "og:image:width",
          //     content: parseInt(img.width),
          //   },
          //   {
          //     property: "og:image:height",
          //     content: parseInt(img.height),
          //   }
        ]}
      />
  );
};
