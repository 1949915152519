import React from "react";
import CustomButtton from "../../../components/Button/CustomButtton";
import { useImx } from "../hooks/useImx";

interface IImxPassportProps {
  isRegister?: boolean;
  registerLogo?: any;
}

export const ImxPassport = ({ isRegister = false, registerLogo }: IImxPassportProps) => {

  const { onImxLogin } = useImx();

  return (
    <div>
      {isRegister ? (
        <CustomButtton
          btnType="cyan"
          text="Create Account"
          icon1={registerLogo}
          btnSize="small"
          onClick={onImxLogin}
        />
      ) : (
        <CustomButtton text="Log in with Passport" onClick={onImxLogin} />
      )}
    </div>
  );
};
