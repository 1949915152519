export enum RealmProviderType {
    AnonUser = "anon-user",
    ApiKey = "api-key",
    LocalUserpass = "local-userpass",
    CustomFunction = "custom-function",
    CustomToken = "custom-token",
    OAuth2Google = "oauth2-google",
    OAuth2Facebook = "oauth2-facebook",
    OAuth2Apple = "oauth2-apple",
}

export enum WalletTags {
    PASSPORT_WALLET = "passport_wallet",
    MEE_REWARD_WALLET = "mee_reward_wallet",
    GAME_WALLET = "game_wallet",
    POLYGON = "polygon"
}