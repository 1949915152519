import "./src/styles/global.css";
import "./src/components/App.css";
//toop tip styles
import 'react-tooltip/dist/react-tooltip.css'
import React from "react";
import Layout from "./src/components/Layout";
import AppProviders from "./src/providers/AppProviders";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// Wraps Root
export const wrapRootElement = ({ element }) => {
  return (
    <AppProviders>
      {element}
    </AppProviders>
  );
};
