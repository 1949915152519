import {
  avatar_kayi_common_0_cover,
  avatar_kayi_common_0_detail,
  avatar_kayi_common_1_cover,
  avatar_kayi_common_1_detail,
  avatar_kayi_common_2_cover,
  avatar_kayi_common_2_detail,
  avatar_kayi_common_3_cover,
  avatar_kayi_common_3_detail,
  avatar_kayi_common_4_cover,
  avatar_kayi_common_4_detail,
  avatar_kayi_common_5_cover,
  avatar_kayi_common_5_detail,
  avatar_kayi_common_6_cover,
  avatar_kayi_common_6_detail,
  avatar_kayi_common_7_cover,
  avatar_kayi_common_7_detail,
  avatar_kayi_common_8_cover,
  avatar_kayi_common_8_detail,
  avatar_kayi_common_9_cover,
  avatar_kayi_common_9_detail,
  avatar_kayi_sketch_0_cover,
  avatar_kayi_sketch_0_detail,
  avatar_kayi_sketch_1_cover,
  avatar_kayi_sketch_1_detail,
  avatar_mp_kayi_female_0_v2_cover,
  avatar_mp_kayi_female_0_v2_detail,
  avatar_mp_kayi_female_0_v3_cover,
  avatar_mp_kayi_female_0_v3_detail,
  avatar_mp_kayi_female_0_v4_cover,
  avatar_mp_kayi_female_0_v4_detail,
  avatar_mp_kayi_male_0_v2_cover,
  avatar_mp_kayi_male_0_v2_detail,
  avatar_mp_kayi_male_0_v3_cover,
  avatar_mp_kayi_male_0_v3_detail,
  avatar_mp_kayi_male_0_v4_cover,
  avatar_mp_kayi_male_0_v4_detail,
  avatar_special_engin_0_cover,
  avatar_special_engin_0_detail,
  avatar_special_halloween_0_cover,
  avatar_special_halloween_0_detail,
  avatar_kayi_special_0_cover,
  avatar_kayi_special_0_detail,
  avatar_kayi_special_1_cover,
  avatar_kayi_special_1_detail
} from "../images/marketplace/avatars";
import {
  silverLCover,
  silverLDetail,
  silverLtag,
  silverMCover,
  silverMDetail,
  silverMtag,
  silverSCover,
  silverSDetail,
  silverStag,
  silverXlCover,
  silverXlDetail,
  silverXltag,
  silverXsCover,
  silverXsDetail,
  silverXstag,
} from "../images/marketplace";
import {
  avatar_kayi_common_0,
  avatar_kayi_common_1,
  avatar_kayi_common_2,
  avatar_kayi_common_3,
  avatar_kayi_common_4,
  avatar_kayi_common_5,
  avatar_kayi_common_6,
  avatar_kayi_common_7,
  avatar_kayi_common_8,
  avatar_kayi_common_9,
  avatar_kayi_default_0,
  avatar_kayi_sketch_0,
  avatar_kayi_sketch_1,
  avatar_mp_kayi_female_0_v2,
  avatar_mp_kayi_female_0_v3,
  avatar_mp_kayi_female_0_v4,
  avatar_mp_kayi_male_0_v2,
  avatar_mp_kayi_male_0_v3,
  avatar_mp_kayi_male_0_v4,
  avatar_special_engin_0,
  avatar_special_halloween_0,
  avatar_kayi_special_0,
  avatar_kayi_special_1
} from "../images/avatars";
import { ContentResources, SkuStatus, SkuType } from "../types/Sku";
import {
  constructionOffer,
  dailyOffer,
  foodOffer,
  freedomOffer,
  headlingOffer,
  monthlyOffer,
  oreIcon,
  oreOffer,
  researchOffer,
  silverIcon,
  speedupConstructionIcon,
  speedupGeneralIcon,
  speedupHealingIcon,
  speedupResearchIcon,
  speedupTrainingIcon,
  trainingOffer,
  wheatIcon,
  woodIcon,
  woodOffer,
} from "../images/marketplace/offers";

export const silverPackagesAssetsMappings = [
  {
    _id: "silver-xl-pack",
    coverImg: silverXlCover,
    tagImg: silverXltag,
    detailImg: silverXlDetail,
  },
  {
    _id: "silver-l-pack",
    coverImg: silverLCover,
    tagImg: silverLtag,
    detailImg: silverLDetail,
  },
  {
    _id: "silver-m-pack",
    coverImg: silverMCover,
    tagImg: silverMtag,
    detailImg: silverMDetail,
  },
  {
    _id: "silver-s-pack",
    coverImg: silverSCover,
    tagImg: silverStag,
    detailImg: silverSDetail,
  },
  {
    _id: "silver-xs-pack",
    coverImg: silverXsCover,
    tagImg: silverXstag,
    detailImg: silverXsDetail,
  },
];

export const avatarsAssetMapping = [
  {
    _id: "avatar-kayi-common-0",
    coverImg: avatar_kayi_common_0_cover,
    avatarImg: avatar_kayi_common_0,
    detailImg: avatar_kayi_common_0_detail,
  },
  {
    _id: "avatar-kayi-common-1",
    coverImg: avatar_kayi_common_1_cover,
    avatarImg: avatar_kayi_common_1,
    detailImg: avatar_kayi_common_1_detail,
  },
  {
    _id: "avatar-kayi-common-2",
    coverImg: avatar_kayi_common_2_cover,
    avatarImg: avatar_kayi_common_2,
    detailImg: avatar_kayi_common_2_detail,
  },
  {
    _id: "avatar-kayi-common-3",
    coverImg: avatar_kayi_common_3_cover,
    avatarImg: avatar_kayi_common_3,
    detailImg: avatar_kayi_common_3_detail,
  },
  {
    _id: "avatar-kayi-common-4",
    coverImg: avatar_kayi_common_4_cover,
    avatarImg: avatar_kayi_common_4,
    detailImg: avatar_kayi_common_4_detail,
  },
  {
    _id: "avatar-kayi-common-5",
    coverImg: avatar_kayi_common_5_cover,
    avatarImg: avatar_kayi_common_5,
    detailImg: avatar_kayi_common_5_detail,
  },
  {
    _id: "avatar-kayi-common-6",
    coverImg: avatar_kayi_common_6_cover,
    avatarImg: avatar_kayi_common_6,
    detailImg: avatar_kayi_common_6_detail,
  },
  {
    _id: "avatar-kayi-common-7",
    coverImg: avatar_kayi_common_7_cover,
    avatarImg: avatar_kayi_common_7,
    detailImg: avatar_kayi_common_7_detail,
  },
  {
    _id: "avatar-kayi-common-8",
    coverImg: avatar_kayi_common_8_cover,
    avatarImg: avatar_kayi_common_8,
    detailImg: avatar_kayi_common_8_detail,
  },
  {
    _id: "avatar-kayi-common-9",
    coverImg: avatar_kayi_common_9_cover,
    avatarImg: avatar_kayi_common_9,
    detailImg: avatar_kayi_common_9_detail,
  },
  {
    _id: "avatar-kayi-sketch-0",
    coverImg: avatar_kayi_sketch_0_cover,
    avatarImg: avatar_kayi_sketch_0,
    detailImg: avatar_kayi_sketch_0_detail,
  },
  {
    _id: "avatar-kayi-sketch-1",
    coverImg: avatar_kayi_sketch_1_cover,
    avatarImg: avatar_kayi_sketch_1,
    detailImg: avatar_kayi_sketch_1_detail,
  },
  {
    _id: "avatar-mp-kayi-female-0-v2",
    coverImg: avatar_mp_kayi_female_0_v2_cover,
    avatarImg: avatar_mp_kayi_female_0_v2,
    detailImg: avatar_mp_kayi_female_0_v2_detail,
  },
  {
    _id: "avatar-mp-kayi-female-0-v3",
    coverImg: avatar_mp_kayi_female_0_v3_cover,
    avatarImg: avatar_mp_kayi_female_0_v3,
    detailImg: avatar_mp_kayi_female_0_v3_detail,
  },
  {
    _id: "avatar-mp-kayi-female-0-v4",
    coverImg: avatar_mp_kayi_female_0_v4_cover,
    avatarImg: avatar_mp_kayi_female_0_v4,
    detailImg: avatar_mp_kayi_female_0_v4_detail,
  },
  {
    _id: "avatar-mp-kayi-male-0-v2",
    coverImg: avatar_mp_kayi_male_0_v2_cover,
    avatarImg: avatar_mp_kayi_male_0_v2,
    detailImg: avatar_mp_kayi_male_0_v2_detail,
  },
  {
    _id: "avatar-mp-kayi-male-0-v3",
    coverImg: avatar_mp_kayi_male_0_v3_cover,
    avatarImg: avatar_mp_kayi_male_0_v3,
    detailImg: avatar_mp_kayi_male_0_v3_detail,
  },
  {
    _id: "avatar-mp-kayi-male-0-v4",
    coverImg: avatar_mp_kayi_male_0_v4_cover,
    avatarImg: avatar_mp_kayi_male_0_v4,
    detailImg: avatar_mp_kayi_male_0_v4_detail,
  },
  {
    _id: "avatar-special-engin-0",
    coverImg: avatar_special_engin_0_cover,
    avatarImg: avatar_special_engin_0,
    detailImg: avatar_special_engin_0_detail,
  },
  {
    headingColor: "orange", //supply if change name and item name color
    _id: "avatar-special-halloween-0",
    coverImg: avatar_special_halloween_0_cover,
    detailImg: avatar_special_halloween_0_detail,
    avatarImg: avatar_special_halloween_0,
    descriptionColor: "orange", //supply if change description color
    description: `<span>Introducing a chilling Halloween avatar:</span> 
        <span>The Scarecrow Warrior!</span>
        <span>Step into a world where medieval warriors meet Halloween horrors. 
        Wear the eerie scarecrow face and conquer the realm with unmatched style and fear. 
        Available for a limited time only – embrace the darkness today!</span>`, //supply if change desc, it can be html or string
  },
  {
    _id: "avatar-kayi-default-0",
    isDefault: true,
    type: SkuType.AVATAR,
    status: SkuStatus.OWNED, //preowned
    avatarImg: avatar_kayi_default_0,
  },
  {
    _id: "avatar-kayi-special-0",
    coverImg: avatar_kayi_special_0_cover,
    avatarImg: avatar_kayi_special_0,
    detailImg: avatar_kayi_special_0_detail,
  },
  {
    _id: "avatar-kayi-special-1",
    coverImg: avatar_kayi_special_1_cover,
    avatarImg: avatar_kayi_special_1,
    detailImg: avatar_kayi_special_1_detail,
  },
];

export const resourceAssetMappings = {
  [ContentResources.SILVERS]: silverIcon,
  [ContentResources.FOOD]: wheatIcon,
  [ContentResources.ORE]: oreIcon,
  [ContentResources.WOOD]: woodIcon,
  [ContentResources.SPEEDUP_BUILDING]: speedupConstructionIcon,
  [ContentResources.SPEEDUP_GENERAL]: speedupGeneralIcon,
  [ContentResources.SPEEDUP_HEALING]: speedupHealingIcon,
  [ContentResources.SPEEDUP_RESEARCH]: speedupResearchIcon,
  [ContentResources.SPEEDUP_TRAINING]: speedupTrainingIcon,
};

export const offersAssetMapping: Record<string, any> = {
  "daily-offer": dailyOffer,
  "monthly-offer": monthlyOffer,
  "food-package": foodOffer,
  "wood-package": woodOffer,
  "ore-package": oreOffer,
  "healing-package": headlingOffer,
  "training-package": trainingOffer,
  "construction-package": constructionOffer,
  "research-package": researchOffer,
  "freedom-package": freedomOffer,
};

export const offersDescriptionMapping: Record<string, string> = {
  "daily-offer":
    "The Daily Offer has a great value with a rich collection of Silver, Food, Wood, Ore, and all type of speed-ups. This time limited offers can only be purchased once a day.",
  "monthly-offer":
    "The Monthly Offer has the greatest value of all. It contains Silver, all kinds of resources, and all type of speed-ups. All in large amount! This offer is renewed monthly and can be only purchased once a month.",
  "food-package":
    "This package includes both Silver and an ample amount of food. It is time-limited and refreshes every week.",
  "wood-package":
    "This package includes silver and a significant amount of wood. It is available for a limited time and is refreshed every week.",
  "ore-package":
    "This package includes Silver and plenty of Ore. It's time-limited and refreshes every week.",
  "healing-package":
    "This offer includes Silver and a large amount of healing speedups. It's time-limited and refreshes every week.",
  "training-package":
    "This offer includes Silver and a large amount of training speedups. It's time-limited and refreshes every week.",
  "construction-package":
    "This offer includes Silver and a large amount of construction speedups. It's time-limited and refreshes every week.",
  "research-package":
    "This offer includes Silver and a large amount of research speedups. It's time-limited and refreshes every week.",
  "freedom-package":
    "This offer includes Silver and a large amount of general speedups. It's time-limited and refreshes every week.",
};
