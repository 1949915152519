import React from "react";
import * as Realm from "realm-web";
//@ts-ignore
import { useRealmApp } from "../../../utils/RealmApp";
import { navigate } from "gatsby";
import { useUiState } from "../../../context/UiContext";
import { getImxIdToken } from "..";
import { constants } from "../../../utils/constants";

export const useImx = () => {
    const realmApp: Realm.App = useRealmApp();
    //global loader
    const {
      actions: { setIsLoading },
    } = useUiState();

    const onImxLogin = async () => {
        setIsLoading(true);
        try {
          const imxIdToken = await getImxIdToken();
          if (imxIdToken) {
            await realmApp.logIn(Realm.Credentials.jwt(imxIdToken));
            navigate(constants.app_routes.account);
          }
        } catch (e) {
          console.log("Error While Imx Login", e);
        }
        setIsLoading(false);
      };

      return { onImxLogin }
}