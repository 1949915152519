import React from "react";
import Modal from "./Modal";
//@ts-ignore
import { useRealmApp } from "../../utils/RealmApp";
import {
  armor,
  chaseTowerIcon,
  closeIcon,
  crossIconWhite,
  kayiAccountImg,
  kayiArcher,
  medImx,
  mobileModalHeader,
  registerModalBg,
} from "../../images/general";
import CustomButtton from "../Button/CustomButtton";
import { navigate } from "gatsby";
import * as Realm from "realm-web";
import { useAuth } from "../../context/AuthContext";
import { constants } from "../../utils/constants";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { ImxPassport } from "../../integrations/imx/components/ImxPassport";

const LoginModal = ({
  isVisible,
  onClose,
  onRegister,
  onForgot,
  location
}: {
  isVisible: boolean;
  onClose: () => void;
  onRegister?: () => void;
  onForgot?: () => void;
  location?: any;
}) => {
  const {
    actions: {
      setIsRegisterModalOpen,
      setIsResetModalOpen
    },
  } = useAuth();
  const realmApp: Realm.App = useRealmApp();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [acceptTerms, setAcceptTerms] = React.useState<
    boolean | "indeterminate"
  >(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>>();
  const [redirect] = useQueryParam("redirect", StringParam);
  const [token, setToken] = useQueryParam("token", StringParam);
  const [tokenId, setTokenId] = useQueryParam("tokenId", StringParam);
  const [emailPassLogin, setEmailPassLogin] = useQueryParam("emailpass", BooleanParam);

  //redirection logic
  const redirectionUri = redirect || constants.app_routes.account;

  const isDisabled = isLoading || !email || !password;

  //confirm email if token and tokenId is given
  React.useEffect(() => {
    if(isVisible) {
      confirmEmail();
    }
  },[token, tokenId, isVisible])

  React.useEffect(() => {
    const { currentUser } = realmApp;
    if(isVisible && currentUser?.isLoggedIn){
      navigate(redirectionUri); //if user logged In redirect to profile
    }
  },[isVisible]);

  const confirmEmail = async () => {
    if(token && tokenId) {
      setIsLoading(true);
      try {
        await realmApp.emailPasswordAuth.confirmUser({ token, tokenId });
      }
      catch(e: any) {
        setErrors({ _: e.error });
      }
      setIsLoading(false);
    }
  }

  const onSubmit = async () => {
    setErrors(undefined);
    setIsLoading(true);

    try {
      await realmApp.logIn(Realm.Credentials.emailPassword(email, password));
      navigate(redirectionUri);
      onLoginModalClose();
    } catch (e: any) {
      if (e?.errorCode && e?.errorCode === "AuthError") {
        //if email not verified, send verification email && navigation to verification email
        await realmApp.emailPasswordAuth.resendConfirmationEmail({ email });
        // navigate("/confirm?email=" + encodeURIComponent(email));
      } 
      if (e.error) {
        setErrors({ _: e.error });
      }
      console.log('REALM ERROR',e);
    } finally {
      setIsLoading(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isLoading) return;
    if (e.code === "Enter") {
      onSubmit();
    }
  };

  const onRegisterClick = () => {
    onLoginModalClose();
    setIsRegisterModalOpen(true);
  }

  const onResetClick = () => {
    onLoginModalClose();
    setIsResetModalOpen(true);
  }

  const onLoginModalClose = () => {
    setToken(undefined); //remove token and token id
    setTokenId(undefined); //remove token and token id
    setEmailPassLogin(undefined);
    onClose();
  }

  return (
    <Modal
      isVisible={isVisible}
      className="bg-[#23262B] sm:max-w[880px] sm:w-[880px] sm:scale-100 w-full scale-90 bg-no-repeat bg-cover bg-center py-2 h-fit m-auto flex sm:flex-row flex-col-reverse"
    >
      <div className="sm:w-[65%] w-full h-[35rem] overflow-y-auto sm:h-auto">
        <div className="sm:px-[3.7rem] px-4 sm:pt-11 pt-3">
          <h3 className="text-2xl sm:text-[40px] leading-10 text-white font-bold font-cinzel sm:mb-8 mb-3">
            Log In
          </h3>
          {!emailPassLogin ? 
          <div className="flex flex-col items-center mt-3">
            <p className="text-white text-md leading-6 mt-3 mb-5">
              Medieval Empires is now using a{" "}
              <a
                href={constants.imx_passport_about}
                target="_blank"
                className="underline text-desert-mist"
              >
                PASSWORDLESS AUTHENTICATION
              </a>{" "}
              method provided by the industry-leading service Immutable
              Passport. Returning players can also use this new method to log
              in. Simply sign in with the same email address you usually use for
              Medieval Empires
            </p>
            <img src={medImx} className="w-80 m-auto mb-10" />
            <ImxPassport />
          </div> : 
          <>
            <form>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-white text-sm leading-6 font-semibold mb-3"
                >
                  Email address *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={onKeyDown}
                  className=" border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="Password"
                  className="block text-white text-sm leading-6 font-semibold mb-3"
                >
                  Password *
                </label>
                <input
                  id="Password"
                  placeholder="Enter your password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={onKeyDown}
                  className="border border-[#667582] rounded-md px-4 py-2 w-full bg-transparent text-white outline-none focus:outline-none h-12"
                />
              </div>
            </form>
            <div className="flex justify-between">
              <div>
                {errors?._ && (
                  <span className="font-garamond text-red">{errors._}</span>
                )}
              </div>
              <span
                className="text-[#7CC0FF] underline cursor-pointer"
                onClick={onResetClick}
              >
                Forgot password?
              </span>
            </div>
            <div className="mt-3">
              <div className="sm:mt-6">
                <CustomButtton
                  text="Login"
                  isLoading={isLoading}
                  icon2={chaseTowerIcon}
                  disabled={isDisabled}
                  onClick={() => !isDisabled && onSubmit()}
                />
                {/* <p className="text-white text-sm leading-6 mt-7 mb-5">
              Or Continue With
              <span className="text-[#7CC0FF] font-semibold underline ml-5 cursor-pointer" onClick={onRegisterClick}>
                Create Account
              </span>
            </p> */}
              </div>
            </div>
          </>}
          <hr className="border-darkborder border w-full mb-4 mt-10" />
          <span
            className="text-[#7CC0FF] underline cursor-pointer flex justify-end"
            onClick={() => setEmailPassLogin(emailPassLogin ? undefined : true)}
          >
            Log in with {emailPassLogin ? "Passport" : "email and password"}
          </span>
        </div>
      </div>
      <div className="sm:w-[35%] w-full bg-no-repeat bg-cover bg-center p-1 relative bg-[url('../images/general/loginModalMobileBg.png')] sm:bg-[url('../images/general/loginModalBg.png')]">
        <div
          className=" hidden sm:flex justify-end cursor-pointer"
          onClick={onLoginModalClose}
        >
          <img src={closeIcon} alt="" />
        </div>
        <div className="hidden sm:block m-0 -ml-8 mt-32">
          <img src={kayiAccountImg} alt="" />
        </div>
        <div
          className="sm:hidden w-full h-16 bg-no-repeat bg-cover bg-center px-8 flex items-center justify-between mb-36"
          style={{ backgroundImage: `url(${mobileModalHeader})` }}
        >
          <h1 className="text-white font-normal text-sm leading-6">Log In</h1>
          <span
            className="text-black text-xl cursor-pointer"
            onClick={onLoginModalClose}
          >
            <img src={crossIconWhite} alt="" />
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
