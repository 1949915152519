import React, { useContext } from "react";
import { Sku } from "../types/Sku";

type SkuContextState = {
  sku: Sku[];
};

type SetSku = {
  type: "SET_SKU";
  payload: Sku[];
};

type SkuContextActions = SetSku;

type SkuContextActionValue = {
  setSku: (sku: Sku[]) => void;
};

type Props = {
  children: React.ReactNode;
};

type SkuContextValues = {
  state: SkuContextState;
  actions: SkuContextActionValue;
};

const SkuContext = React.createContext<SkuContextValues>({
  state: {
    sku: [],
  },
  actions: {
    setSku: () => {},
  },
});

const reducer = (
  state: SkuContextState,
  { type, payload }: SkuContextActions
): SkuContextState => {
  switch (type) {
    case "SET_SKU":
      return {
        ...state,
        sku: payload,
      };
    default:
      return state;
  }
};

const SkuContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    sku: [],
  });

  const actions = {
    setSku: (sku: Sku[]) => {
      dispatch({ type: "SET_SKU", payload: sku });
    },
  };

  return (
    <SkuContext.Provider value={{ state, actions }}>
      {children}
    </SkuContext.Provider>
  );
};

const useSkuState = () => useContext(SkuContext);

export { SkuContextProvider, useSkuState };
